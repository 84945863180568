<template>
  <main id="CustomizeList">
    <div class="row">
      <header>
        <p class="sub_title"><b>CUSTOMIZE PRODUCT</b></p>
        <h3 class="title title_mark"><b>客製化選色</b></h3>
      </header>

      <div class="list_header">
        <div class="select_box">
          <select v-model="active_category">
            <option value="all">所有商品</option>
            <option value="前避震">前避震</option>
            <option value="後避震">後避震</option>
          </select>
          <span class="material-icons-outlined"> expand_more </span>
        </div>
      </div>
      <div class="content_box">
        <h4 class="list_title">檔車專用</h4>
        <ol class="card_list">
          <li
            class="card"
            v-for="item in active_moto_products"
            :key="item.product_id"
          >
            <router-link
              @click.native="GA_ChooseCustomizeProduct(item)"
              :to="`/customize/${item.product_id}`"
              class="link_mark"
            >
              <div class="header">
                <h4>{{ item.name }}</h4>
              </div>
              <div class="img_box">
                <img :src="`/img/customize/${item.name}/thumbnail.png`" />
                <img :src="`/img/customize/${item.name}/thumbnail.png`" />
              </div>
            </router-link>
          </li>
        </ol>
        <h4 class="list_title">速可達專用</h4>
        <ol class="card_list">
          <li
            class="card"
            v-for="item in active_products"
            :key="item.product_id"
          >
            <router-link
              :to="`/customize/${item.product_id}`"
              class="link_mark"
            >
              <div class="header">
                <h4>{{ item.name }}</h4>
              </div>
              <div class="img_box">
                <img :src="`/img/customize/${item.name}/thumbnail.png`" />
                <img :src="`/img/customize/${item.name}/thumbnail.png`" />
              </div>
            </router-link>
          </li>
        </ol>
      </div>
    </div>
    <MainFooter />
  </main>
</template>

<script>
import SmoothScrollResister from '@/common/SmoothScrollResister.js';
import MainFooter from '@/components/MainFooter';
import { GetMetaData } from '@/common/meta.js';
export default {
  name: 'CustomizeList',
  mixins: [SmoothScrollResister],
  components: {
    MainFooter,
  },
  data() {
    return {
      products: [
        {
          product_id: 'SR401',
          name: 'SR401',
          type: '後避震',
        },
        {
          product_id: 'SR402',
          name: 'SR402',
          type: '後避震',
        },
        {
          product_id: 'MR401',
          name: 'MR401',
          type: '後避震',
        },
        {
          product_id: 'WR401',
          name: 'WR401',
          type: '後避震',
        },
        {
          product_id: 'WR401H',
          name: 'WR401H',
          type: '後避震',
        },
        {
          product_id: 'SR402PA',
          name: 'SR402PA',
          type: '後避震',
        },
        {
          product_id: 'WR401PAH',
          name: 'WR401PAH',
          type: '後避震',
        },
        {
          product_id: 'SF351',
          name: 'SF351',
          type: '前避震',
        },
        {
          product_id: 'MF351',
          name: 'MF351',
          type: '前避震',
        },
        {
          product_id: 'WF351',
          name: 'WF351',
          type: '前避震',
        },
      ],
      moto_products: [
        {
          product_id: 'SR402-檔車',
          name: 'SR402',
          type: '後避震',
        },
        {
          product_id: 'WR401H-檔車',
          name: 'WR401H',
          type: '後避震',
        },
        {
          product_id: 'SR402PA-檔車',
          name: 'SR402PA',
          type: '後避震',
        },
        {
          product_id: 'WR401PAH-檔車',
          name: 'WR401PAH',
          type: '後避震',
        },
        {
          product_id: 'WF352',
          name: 'WF352',
          type: '前避震',
        },
      ],
      active_category: 'all',
      meta_data: null,
    };
  },
  computed: {
    active_products() {
      if (this.active_category == 'all') {
        return this.products;
      } else {
        return this.products.filter(
          (item) => item.type == this.active_category
        );
      }
    },
    active_moto_products() {
      if (this.active_category == 'all') {
        return this.moto_products;
      } else {
        return this.moto_products.filter(
          (item) => item.type == this.active_category
        );
      }
    },
  },
  mounted() {
    this.RegisterGsap('CustomizeList');
    this.$emit('init-cursor');
    window.prerenderReady = true;
  },
  created() {
    this.meta_data = GetMetaData(
      '客製化',
      '歡迎使用MJP 茂建科技懸吊客製化系統，您可以依照個人喜好進行懸吊外觀客製化。',
      ''
    );
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
